/* eslint-disable no-await-in-loop */
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { collection, deleteDoc, getFirestore, onSnapshot, query, where } from 'firebase/firestore';

import { SetStatus } from '@/Enums/enum';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
const removeCommentTags = (text: string): string => {
	const regex =
		/<i(?=\s)(?!(?:[^>"\\']|"[^"]*"|\\'[^\\']*\\')*?(?<=\s)(?:term|range)\s*=)(?!\s*>)\s+(?:".*?"|\\'.*?\\'|[^>]*?)+>|<\/i>/gm;

	return text.replace(regex, '');
};

export const useGetAllAdmin = () => {
	const query = gql`
		query GetAllAdmin {
			users(where: { user_role: { _eq: "admin" } }) {
				user_email
				user_first
				user_last
			}
		}
	`;

	return useQuery(
		['get-all-admin'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const { users } = await graphQLClient.request(query);
			return users;
		},
		{
			staleTime: Infinity,
		}
	);
};

export function useGetAllTags() {
	const query = gql`
		query GetAllTags {
			tags(order_by: { tag_name: asc }) {
				tag_id
				tag_color
				tag_name
				tag_definition
				tag_discipline
			}
		}
	`;

	return useQuery(
		['get-all-tags-admin'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const { tags } = await graphQLClient.request(query);
			return tags;
		},
		{
			staleTime: Infinity,
		}
	);
}

interface updateDraftFragProps {
	userRole: string;
	variables: {
		courseDraft: string;
		courseId: string;
		isDraft?: boolean;
	};
}

export const updateDraftFrag = async (config: updateDraftFragProps) => {
	const { variables, userRole } = config;
	const { courseId, courseDraft, isDraft } = variables;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	if (isDraft) {
		const courseMutation = gql`
			mutation updateCourse($courseId: uuid!, $courseDraft: String, $isDraft: Boolean) {
				update_courses_by_pk(
					pk_columns: { course_id: $courseId }
					_set: { working_copy: $courseDraft, is_saved_draft: $isDraft }
				) {
					course_id
					submission_id
				}
			}
		`;

		return graphQLClient.request(courseMutation, {
			courseId,
			courseDraft: removeCommentTags(courseDraft),
			isDraft: !!isDraft,
		});
	}

	const courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $courseDraft: String, $isDraft: Boolean) {
			update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: {
					is_saved_draft: $isDraft
					course_draft: $courseDraft
					working_copy: $courseDraft
				}
			) {
				course_id
				submission_id
			}
		}
	`;

	return graphQLClient.request(courseMutation, {
		courseId,
		courseDraft,
		isDraft: !!isDraft,
	});
};

export const existingSub = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const subQuery = gql`
		query GetCourseSub($courseId: uuid) {
			submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	const { submissions } = await graphQLClient.request(subQuery, variables);
	return submissions[0]?.submission_id;
};

export const updateSubStatusFrag = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const subQuery = gql`
		query GetCourseSub($courseId: uuid) {
			submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	const { submissions } = await graphQLClient.request(subQuery, variables);
	const subId = submissions[0]?.submission_id;
	const subMutation = gql`
		mutation MyMutation($subId: uuid) {
			update_submissions(
				where: { submission_id: { _eq: $subId } }
				_set: { admin_approval: "waiting", dept_approval: "waiting" }
			) {
				affected_rows
			}
		}
	`;

	return graphQLClient.request(subMutation, { subId });
};

// eslint-disable-next-line consistent-return
export const setDraftFrag = async (config: any) => {
	const { variables, userRole } = config;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', userRole);

	const { courseId, status, approveAll } = variables;
	const { draftCourse, publishedCourse } = config;
	const approvalType = userRole === 'admin' ? 'admin_approval' : 'dept_approval';

	const subIdQuery = gql`
		query getSubId($courseId: uuid!) {
			submissions(where: { course_id: { _eq: $courseId } }) {
				submission_id
			}
		}
	`;

	await graphQLClient.request(subIdQuery, {
		courseId,
	});

	let courseMutation: string;
	if (approveAll) {
		courseMutation = gql`
			mutation updateCourse($courseId: uuid!, $status: String) {
				update_submissions(
					where: { course_id: { _eq: $courseId } }
					_set: { admin_approval: $status, dept_approval: $status }
				) {
					returning {
						admin_approval
						dept_approval
					}
				}
			}
		`;
	} else {
		courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $status: String) {
			update_submissions(
				where: { course_id: { _eq: $courseId } }
				_set: { ${approvalType}: $status }
			) {
				returning {
					admin_approval
					dept_approval
				}
			}
		}
	`;
	}

	const { update_submissions: approvalStatus } = await graphQLClient.request(courseMutation, {
		courseId,
		status,
	});

	const submission = approvalStatus.returning[0];
	const isFullyApproved =
		submission.admin_approval === SetStatus.APPROVED &&
		submission.dept_approval === SetStatus.APPROVED;

	const deleteOutcomesMutation = gql`
		mutation deleteOutcomesMutation($outcomeId: uuid!) {
			delete_outcomes(where: { outcome_id: { _eq: $outcomeId } }) {
				affected_rows
			}
		}
	`;

	const createOutcomeMutation = gql`
		mutation OutcomeMutation($outcomeText: String, $outcomeOrder: Int) {
			insert_outcomes_one(
				object: { outcome_text: $outcomeText, outcome_order: $outcomeOrder }
			) {
				outcome_id
			}
		}
	`;
	const createCourseOutcomeMutation = gql`
		mutation CourseOutcomeMutation($courseId: uuid, $outcomeId: uuid) {
			insert_courses_outcomes(objects: { course_id: $courseId, outcome_id: $outcomeId }) {
				affected_rows
			}
		}
	`;

	async function deletePublishedOutcomes() {
		const promises = [];
		const publishedOutcomeLen = publishedCourse.courses_outcomes?.length;
		for (let i = 0; i < publishedOutcomeLen; i += 1) {
			const { outcome } = publishedCourse.courses_outcomes[i];
			const { outcome_id: outcomeId } = outcome;

			if (outcomeId) {
				promises.push(
					graphQLClient.request(deleteOutcomesMutation, {
						outcomeId,
					})
				);
			}
		}
		await Promise.all(promises);
	}

	if (publishedCourse.courses_outcomes?.length > 0 && isFullyApproved) {
		await deletePublishedOutcomes();
	}

	async function publishDraftOutcomes() {
		const promises = [];
		const draftOutcomeLen = draftCourse.courses_outcomes?.length;

		for (let i = 0; i < draftOutcomeLen; i += 1) {
			const { outcome } = draftCourse.courses_outcomes[i];
			const { outcome_text: outcomeText, outcome_order: outcomeOrder = 0 } = outcome;

			const { insert_outcomes_one: insertedOutcome } = await graphQLClient.request(
				createOutcomeMutation,
				{
					outcomeText,
					outcomeOrder,
				}
			);

			promises.push(
				graphQLClient.request(createCourseOutcomeMutation, {
					outcomeId: insertedOutcome.outcome_id,
					courseId,
				})
			);
		}
		await Promise.all(promises);
	}

	if (draftCourse.courses_outcomes?.length > 0 && isFullyApproved) {
		await publishDraftOutcomes();
	}

	const createResourceMutation = gql`
		mutation ResourceMutation(
			$resourceAuthor: String
			$resourceDetail: String
			$resourceIsbn: String
			$resourceType: String
			$resourceTitle: String
		) {
			insert_resources_one(
				object: {
					resource_author: $resourceAuthor
					resource_detail: $resourceDetail
					resource_isbn: $resourceIsbn
					resource_type: $resourceType
					resource_title: $resourceTitle
				}
			) {
				resource_id
			}
		}
	`;
	const createCourseResourceMutation = gql`
		mutation CourseResourceMutation($courseId: uuid, $resourceId: uuid) {
			insert_courses_resources(objects: { course_id: $courseId, resource_id: $resourceId }) {
				affected_rows
			}
		}
	`;

	const deleteResourceMutation = gql`
		mutation DeleteResourceMutation($resourceId: uuid!) {
			delete_resources(where: { resource_id: { _eq: $resourceId } }) {
				affected_rows
			}
		}
	`;

	async function deletePublishedResources() {
		const promises = [];
		const publishedResourcesLen = publishedCourse.courses_resources?.length;

		for (let i = 0; i < publishedResourcesLen; i += 1) {
			const { resource } = publishedCourse.courses_resources[i];
			const { resource_id: resourceId } = resource;

			if (resourceId) {
				promises.push(
					graphQLClient.request(deleteResourceMutation, {
						resourceId,
					})
				);
			}
		}

		await Promise.all(promises);
	}

	if (publishedCourse.courses_resources?.length > 0 && isFullyApproved) {
		await deletePublishedResources();
	}

	async function publishDraftResources() {
		const promises = [];
		const draftResourcesLen = draftCourse.courses_resources?.length;

		for (let i = 0; i < draftResourcesLen; i += 1) {
			const { resource } = draftCourse.courses_resources[i];

			const {
				resource_author: resourceAuthor,
				resource_detail: resourceDetail,
				resource_isbn: resourceIsbn,
				resource_type: resourceType,
				resource_title: resourceTitle,
			} = resource;

			const { insert_resources_one: insertedResource } = await graphQLClient.request(
				createResourceMutation,
				{
					resourceAuthor,
					resourceDetail,
					resourceIsbn,
					resourceType,
					resourceTitle,
				}
			);

			promises.push(
				graphQLClient.request(createCourseResourceMutation, {
					resourceId: insertedResource.resource_id,
					courseId,
				})
			);
		}

		await Promise.all(promises);
	}

	if (draftCourse.courses_resources?.length > 0 && isFullyApproved) {
		await publishDraftResources();
	}

	const createFocusMutation = gql`
		mutation FocusMutation($focusTitle: String, $focusOrder: Int) {
			insert_focuses_one(object: { focus_title: $focusTitle, focus_order: $focusOrder }) {
				focus_id
			}
		}
	`;
	const createSkillMutation = gql`
		mutation SkillMutation($skillText: String, $skillType: String) {
			insert_skills_one(object: { skill_text: $skillText, skill_type: $skillType }) {
				skill_id
			}
		}
	`;

	const createFocusSkillMutation = gql`
		mutation FocusSkillMutation($skillId: uuid, $focusId: uuid) {
			insert_focuses_skills_one(object: { skill_id: $skillId, focus_id: $focusId }) {
				focuses_skills_id
			}
		}
	`;

	const deleteFocusMutation = gql`
		mutation DeleteFocusMutation($focusId: uuid!) {
			delete_focuses(where: { focus_id: { _eq: $focusId } }) {
				affected_rows
			}
		}
	`;

	const deleteSkillMutation = gql`
		mutation DeleteSkillMutation($skillId: uuid!) {
			delete_skills(where: { skill_id: { _eq: $skillId } }) {
				affected_rows
			}
		}
	`;

	const deleteFocusSkillMutation = gql`
		mutation DeleteSkillMutation($focusSkillId: uuid!) {
			delete_focuses_skills(where: { focuses_skills_id: { _eq: $focusSkillId } }) {
				affected_rows
			}
		}
	`;

	const createCourseFocusesMutation = gql`
		mutation CourseFocusesMutation($courseId: uuid, $focusId: uuid) {
			insert_courses_focuses_one(object: { course_id: $courseId, focus_id: $focusId }) {
				focus_id
			}
		}
	`;

	async function deletePublishedFocuses() {
		const promises = [];
		const publishedFocusLen = publishedCourse.courses_focuses?.length;

		for (let i = 0; i < publishedFocusLen; i += 1) {
			const { focus } = publishedCourse.courses_focuses[i];

			const skillLen = focus?.focuses_skills?.length;
			for (let sIdx = 0; sIdx < skillLen; sIdx += 1) {
				const skill = focus.focuses_skills[sIdx]?.skill;
				const focusSkillId = focus.focuses_skills[sIdx].focuses_skills_id;

				if (focusSkillId) {
					promises.push(
						graphQLClient.request(deleteFocusSkillMutation, {
							focusSkillId,
						})
					);
				}

				if (skill) {
					const skillId = skill.skill_id;
					promises.push(
						graphQLClient.request(deleteSkillMutation, {
							skillId,
						})
					);
				}
			}

			const { focus_id: focusId } = focus;
			if (focusId) {
				promises.push(
					graphQLClient.request(deleteFocusMutation, {
						focusId,
					})
				);
			}
		}

		await Promise.all(promises);
	}

	if (publishedCourse.courses_focuses?.length > 0 && isFullyApproved) {
		await deletePublishedFocuses();
	}

	async function publishDraftFocuses() {
		const promises = [];
		const draftFocusLen = draftCourse.courses_focuses?.length;

		for (let i = 0; i < draftFocusLen; i += 1) {
			const { focus } = draftCourse.courses_focuses[i];
			const { focus_order: focusOrder, focus_title: focusTitle } = focus;
			const { insert_focuses_one: insertedFocus } = await graphQLClient.request(
				createFocusMutation,
				{
					focusTitle,
					focusOrder,
				}
			);

			promises.push(
				await graphQLClient.request(createCourseFocusesMutation, {
					focusId: insertedFocus.focus_id,
					courseId,
				})
			);

			const skillLen = focus?.focuses_skills?.length;
			for (let sIdx = 0; sIdx < skillLen; sIdx += 1) {
				const skill = focus.focuses_skills[sIdx]?.skill;

				if (skill) {
					const { skill_text: skillText, skill_type: skillType } = skill;
					const skillDefaultType =
						skill.skill_type === '' || skill.skill_type === null ? 'topic' : skillType;

					const { insert_skills_one: insertedSkill } = await graphQLClient.request(
						createSkillMutation,
						{
							skillText,
							skillType: skillDefaultType,
						}
					);

					promises.push(
						await graphQLClient.request(createFocusSkillMutation, {
							skillId: insertedSkill.skill_id,
							focusId: insertedFocus.focus_id,
						})
					);
				}
			}
		}
		await Promise.all(promises);
	}

	if (draftCourse.courses_focuses?.length > 0 && isFullyApproved) {
		await publishDraftFocuses();
	}

	if (isFullyApproved) {
		// delete all submission comments if course is fully approved
		const db = getFirestore();
		const msgPath = `${import.meta.env.MODE}/comments/${courseId}`;
		const q = query(collection(db, `${msgPath}`), where('commentType', '==', 'submission'));
		onSnapshot(q, (snap) => {
			if (!snap.empty) {
				snap.forEach((doc) => {
					deleteDoc(doc.ref);
				});
			}
		});

		const courseBasicMutation = gql`
			mutation updateCourse(
				$courseId: uuid!
				$courseDescription: String
				$courseExtras: String
				$coursePrereq: String
				$courseResourceInfo: String
			) {
				update_courses_by_pk(
					pk_columns: { course_id: $courseId }
					_set: {
						course_description: $courseDescription
						course_resource_info: $courseResourceInfo
						course_extras: $courseExtras
						course_prereq: $coursePrereq
					}
				) {
					course_id
				}
			}
		`;

		const {
			course_description: courseDescription,
			course_resource_info: courseResourceInfo,
			course_extras: courseExtras,
			course_prereq: coursePrereq,
		} = draftCourse;
		await graphQLClient.request(courseBasicMutation, {
			courseId,
			courseDescription,
			courseResourceInfo,
			courseExtras,
			coursePrereq,
		});

		const queryCurrentCourse = gql`
			query currentCourse($courseId: uuid!) {
				courses_by_pk(course_id: $courseId) {
					course_name
					course_id
					course_description
					course_resource_info
					course_prereq
					course_extras
					course_discipline
					course_division
					course_draft
					courses_outcomes {
						courses_outcomes_id
						outcome {
							outcome_order
							outcome_text
							outcome_id
						}
					}
					courses_resources {
						course_resources_id
						resource {
							resource_author
							resource_detail
							resource_isbn
							resource_id
							resource_title
							resource_type
						}
					}
					courses_focuses {
						courses_focuses_id
						focus {
							focus_title
							focus_type
							focus_order
							focus_id
							focuses_skills {
								focuses_skills_id
								skill {
									skill_id
									skill_text
									skill_type
								}
							}
						}
					}
				}
			}
		`;

		const { courses_by_pk: course } = await graphQLClient.request(queryCurrentCourse, {
			courseId,
		});

		const newDraft = JSON.stringify(course);

		const courseNewDraftMutation = gql`
			mutation newDraftMutation($courseDraft: String!, $courseId: uuid!, $lastUpdated: date) {
				update_courses_by_pk(
					pk_columns: { course_id: $courseId }
					_set: { course_draft: $courseDraft, last_updated: $lastUpdated }
				) {
					course_id
				}
			}
		`;

		return graphQLClient.request(courseNewDraftMutation, {
			lastUpdated: new Date(),
			courseDraft: newDraft,
			courseId,
		});
	}
};

export const useGetCurrentCourses = (userRoleData: any, courseId?: string) => {
	const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
	const query = gql`
		query GetCurrentCourses($courseId: uuid!) {
			courses(where: { course_id: { _eq: $courseId } }) {
				course_name
				course_id
				course_description
				course_resource_info
				course_prereq
				course_extras
				course_discipline
				course_division
				submission_id
				course_draft
				is_archived
				working_copy
				course_lesson_plans
				is_saved_draft
				last_updated
				submission {
					admin_approval
					dept_approval
				}
				courses_discipline {
					discipline {
						name
					}
				}
				courses_users {
					user {
						user_first
						user_last
						user_id
						user_email
						dept_chair {
							user_email
							user_id
						}
					}
				}
				courses_outcomes {
					courses_outcomes_id
					outcome {
						outcome_order
						outcome_text
						outcome_id
					}
				}
				courses_resources {
					course_resources_id
					resource {
						resource_author
						resource_detail
						resource_isbn
						resource_id
						resource_title
						resource_type
					}
				}
				courses_focuses {
					courses_focuses_id
					focus {
						focus_title
						focus_type
						focus_order
						focus_id
						focuses_skills {
							focuses_skills_id
							skill {
								skill_id
								skill_text
								skill_type
							}
						}
					}
				}
			}
		}
	`;

	return useQuery(
		['get-current-course'],
		async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();
			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('x-hasura-role', userRoleData.user_role);
			graphQLClient.setHeader('content-type', 'application/json');
			const { courses } = await graphQLClient.request(query, {
				courseId,
			});

			return courses[0];
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
};

export const updateLessonPlans = async ({
	plans,
	courseId,
}: {
	plans: LessonSection[];
	courseId: string;
}) => {
	const jsonPlans = JSON.stringify(plans);
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $plans: String!) {
			update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: { course_lesson_plans: $plans }
			) {
				course_id
			}
		}
	`;
	return graphQLClient.request(courseMutation, {
		courseId,
		plans: jsonPlans,
	});
};

export const saveWorkingDraft = async (draft: any, courseId: string) => {
	const jsonDraft = JSON.stringify(draft);

	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken(true);

	graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const courseMutation = gql`
		mutation updateCourse($courseId: uuid!, $draft: String!) {
			update_courses_by_pk(
				pk_columns: { course_id: $courseId }
				_set: { course_draft: $draft }
			) {
				course_id
			}
		}
	`;
	return graphQLClient.request(courseMutation, {
		courseId,
		draft: jsonDraft,
	});
};
