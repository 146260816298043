import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { GlobalModalStyle, StyledButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import { StyledH5, StyledP, StyledH4 } from '@/Shared/Typography/typography';
import { CloseIcon } from '../../icons';

const StyledActionsContainer = styled.div`
	button {
		margin-right: var(--spacing-4);
		min-width: 90px;
	}
`;

const StyledModalBody = styled.div`
	margin-bottom: var(--spacing-7);
`;

interface ConfirmModalProps {
	message: string;
	selectedData: string;
	modalActive: boolean;
	triggerModal(): void;
	onConfirm(): void;
	onCancel(): void;
	children?: React.ReactNode;
	validated?: boolean;
}
const ConfirmModal = ({
	message,
	selectedData,
	modalActive,
	triggerModal,
	onConfirm,
	onCancel,
	children,
	validated = true,
}: ConfirmModalProps) => {
	const modalStyles: ReactModal.Styles = {
		content: {
			maxWidth: '500px',
			maxHeight: '900px',
			height: 'auto',
			width: '95%',
			margin: 'auto',
			position: 'relative',
			overflow: 'visible',
			backgroundColor: 'var(--modal-bg)',
			inset: 'initial',
		},
		overlay: {
			backgroundColor: 'var(--modal-overlay)',
			display: 'flex',
			justifyContent: 'center',
			zIndex: 1,
		},
	};

	const [isConfirming, setIsConfirming] = useState(false);

	const handleConfirm = async () => {
		setIsConfirming(true);
		await onConfirm();
		triggerModal();
	};
	const rootElm = document.querySelector('#root') as HTMLDivElement;

	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={modalActive}
			onRequestClose={triggerModal}
			onAfterClose={() => setIsConfirming(false)}
			ariaHideApp={!modalActive}
			contentLabel="Confirmation Modal"
			appElement={rootElm}
			style={modalStyles}>
			<div>
				<StyledH4 mb="16px">Confirm Action</StyledH4>
				<CloseIcon passedEvent={triggerModal} />
			</div>
			<GlobalModalStyle />
			<StyledModalBody>
				<StyledP mb="0">{message}</StyledP>
				<StyledH5 mb="0">{selectedData}</StyledH5>
				{children}
			</StyledModalBody>
			<StyledActionsContainer>
				{isConfirming && (
					<StyledButton disabled={isConfirming} color="green" size="small">
						Working...
					</StyledButton>
				)}
				{!isConfirming && (
					<>
						<StyledButton
							autoFocus
							disabled
							onClick={handleConfirm}
							type="button"
							color="green"
							size="small">
							Yes
						</StyledButton>
						<StyledSecondaryButton
							disabled={isConfirming || !validated}
							onClick={onCancel}
							type="button"
							size="small">
							Cancel
						</StyledSecondaryButton>
					</>
				)}
			</StyledActionsContainer>
		</ReactModal>
	);
};

export default ConfirmModal;
