/* eslint-disable */
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import { v4 as uuidv4 } from 'uuid';
import StarterKit from '@tiptap/starter-kit';
import { useContext, useState, useEffect } from 'react';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';
// import Collaboration from '@tiptap/extension-collaboration';
import * as Y from 'yjs';
// import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorView } from 'prosemirror-view';

import scrollIntoView from 'scroll-into-view';
import CourseEditContext from '@/Pages/Courses/CourseEditContext';
import GlobalContext from '@/context/GlobalContext';
import { StyledPrimaryButton, StyledSecondaryButton } from '@/Shared/StyledElements';
import { EditorType } from '@/Enums/enum';
import { Comment } from './extensions/comment';
import { Tagged } from './extensions/tagged';
import TiptapMenuBar from './TiptapMenuBar';
import { StyledEditor } from './TipTapEditor.Styles';
import { useCourseEditStore } from '@/stores/courseEditStore';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { Video } from './extensions/embed';

const dateTimeFormat = 'LLL d, yyyy h:mm a';

interface CommentInstance {
	id?: string;
	comments?: any[];
}

interface TipTapEditorProps {
	content: string;
	onChange: (content: string) => void;
	commentAddedCallback?: (string: string) => Promise<void> | void;
	placeholder?: string;
	type?: string;
	className?: string;
	isEditable?: boolean;
	id?: string;
	documentRef: Y.Doc;
	providerRef: HocuspocusProvider;
}

const TipTapEditor = ({
	content,
	onChange,
	placeholder = '',
	type = '',
	isEditable = true,
	className = '',
	id = '',
	documentRef,
	providerRef,
	commentAddedCallback,
}: TipTapEditorProps) => {
	const { updateEditorList } = useCourseEditStore();
	const { currentUser } = useContext(GlobalContext);
	const generateHex = () => {
		const name = `${currentUser?.user_first} ${currentUser?.user_last}`;
		const numberName =
			name
				.split('')
				.map((str) => str.charCodeAt(0) - 97)
				.reduce((a, b) => Math.abs(a) + Math.abs(b), 0) + 16277000;
		return `#${Math.floor(numberName).toString(16)}`;
	};

	// TODO: workaround to fix for sync error - keep eye on release to eventually remove
	// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
	EditorView.prototype.updateState = function updateState(state) {
		if (!(this as any).docView) return; // This prevents the matchesNode error on hot reloads
		(this as any).updateStateInner(state, this.state.plugins != state.plugins);
	};

	const editor = useEditor(
		{
			extensions: [
				StarterKit.configure({
					//TODO: enable when collaboration extension is fixed
					// history: false, // set to false to disable history because collaboration extension handles history
				}),
				//TODO: fix the sync issue with the editor
				// when you switch tabs and come back to the editor, the content is doubled
				// Collaboration.configure({
				// 	document: documentRef,
				// }),
				// CollaborationCursor.configure({
				// 	provider: providerRef,
				// 	user: {
				// 		name: `${currentUser?.user_first} ${currentUser?.user_last}`,
				// 		color: generateHex(),
				// 		user_id: currentUser?.user_id,
				// 		isActive: false,
				// 	},
				// }),
				Comment,
				Video,
				Tagged,
				Underline,
				Link.configure({
					openOnClick: false,
				}),
				Placeholder.configure({
					showOnlyWhenEditable: true,
					placeholder,
				}),
			],
			onUpdate: ({ editor }) => {
				onChange?.(editor.getHTML());
			},
			content,
			editable: isEditable,
			editorProps: {
				attributes: {
					spellcheck: 'false',
				},
			},
		},
		[id, type]
	);

	// TODO: fix the sync issue with the editor
	providerRef.on('synced', () => {
		// if (providerRef.isSynced && providerRef.document.store.clients.size === 0) {
		// 	Y.applyUpdate(documentRef, Y.encodeStateAsUpdate(documentRef));
		// 	editor?.commands.setContent(content);
		// } else {
		editor?.commands.setContent(content);
		// }
	});

	const [isCommentModeOn, setIsCommentModeOn] = useState(false);
	const [commentText, setCommentText] = useState('');

	const { courseData } = useContext(CourseEditContext);

	const setComment = async () => {
		if (!commentText.trim().length) return;

		const db = getFirestore();
		const commenterName = `${currentUser?.user_first} ${currentUser?.user_last}`;

		const currentComment = {
			text: commentText.trim(),
			userName: commenterName,
			userId: currentUser?.user_id,
			documentCreatedAt: Timestamp.now(),
			editorType: type,
			commentType: 'general',
			thread: [],
			id: uuidv4(),
			isEdited: false,
		} as UserComment;

		const msgPath = `${import.meta.env.MODE}/comments/${courseData.course_id}`;
		await addDoc(collection(db, msgPath), currentComment);

		const commentWithUuid = JSON.stringify({
			id: currentComment.id,
			comments: [currentComment],
		});

		editor?.chain().setComment(commentWithUuid).run();

		if (commentAddedCallback && editor) {
			commentAddedCallback(editor.getHTML());
		}

		setTimeout(() => setCommentText(''), 50);
		setIsCommentModeOn(false);
		editor?.chain().focus();
	};

	useEffect(() => {
		if (editor) {
			updateEditorList({
				[type]: editor,
			});
		}
	}, [editor]);

	if (!editor) {
		return null;
	}

	// editor.on('blur', ({ editor, transaction }) => {
	// 	if (!transaction.isGeneric) return;
	// 	if (editor && currentUser) {
	// 		editor
	// 			.chain()
	// 			?.focus()
	// 			?.updateUser({
	// 				...currentUser,
	// 				name: `${currentUser.user_first} ${currentUser.user_last}`,
	// 				color: generateHex(),
	// 				isActive: false,
	// 			})
	// 			?.run();
	// 	}
	// });

	const removeActiveClass = () => {
		document.querySelector('.comment-container.is-active')?.classList.remove('is-active');
	};

	editor.on('blur', ({ editor, transaction }) => {
		if (!transaction.isGeneric || !editor.isActive('comment')) return;
		removeActiveClass();
	});

	editor.on('selectionUpdate', ({ editor, transaction }) => {
		removeActiveClass();
		if (!transaction.isGeneric || !editor.isActive('comment')) return;
		const commentId = JSON.parse(editor.getAttributes('comment').comment).id;
		const commentCardElement = document.querySelector(
			`[data-comment-source="comment-${commentId}"]`
		) as HTMLElement;
		if (commentCardElement) {
			commentCardElement.classList.add('is-active');
			scrollIntoView(commentCardElement, {
				align: {
					topOffset: 0,
				},
			});
		}
	});

	return (
		<StyledEditor className={`tiptap ${className}`}>
			{editor && <TiptapMenuBar editor={editor} type={type} />}
			<section className="tiptap-container">
				<EditorContent className="editor-content" editor={editor} />
				<BubbleMenu
					tippyOptions={{
						interactive: true,
						placement: 'auto',
						onHide: () => {
							editor?.setEditable(true);
							setIsCommentModeOn(false);
						},
						onClickOutside: (instance) => {
							// conditionally call hide() to prevent memory leak
							// in case instance is undefined
							instance?.hide();
							editor?.setEditable(true);
							setIsCommentModeOn(false);
						},
					}}
					editor={editor}
					className="bubble-menu"
					shouldShow={({ editor }) => {
						return !editor.state.selection.empty && type !== EditorType.STANDARD;
					}}>
					<>
						{!isCommentModeOn && !editor.isActive('video') && (
							<button
								onClick={() => {
									editor?.setEditable(false);
									setIsCommentModeOn(true);
								}}
								type="button"
								className="comment-mode-toggle">
								<svg
									width="15"
									viewBox="0 0 470 470"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M 235 0 A 235 235 0 0 0 33 354 L 2 441 a 21 21 0 0 0 27 27 l 87 -31 A 235 235 0 1 0 235 0 Z Z"
										fill="white"
									/>
								</svg>
								Add Comment
							</button>
						)}
						{isCommentModeOn && (
							<section className="comment-popup-section">
								<textarea
									value={commentText}
									onInput={(e) => setCommentText((e.target as any).value)}
									onKeyDown={(e) => {
										if (e.code === 'Enter') {
											e.preventDefault();
											e.stopPropagation();
											setComment();
										}
									}}
									cols={30}
									rows={4}
									placeholder="Add comment..."
								/>
								<div>
									<StyledPrimaryButton
										type="button"
										size="small"
										className="small-btn"
										onClick={setComment}>
										Add Comment
									</StyledPrimaryButton>

									<StyledSecondaryButton
										type="button"
										size="small"
										className="small-btn"
										onClick={() => {
											setCommentText('');
											editor?.setEditable(true);
											setIsCommentModeOn(false);
										}}>
										Cancel
									</StyledSecondaryButton>
								</div>
							</section>
						)}
					</>
				</BubbleMenu>
			</section>
		</StyledEditor>
	);
};

export default TipTapEditor;
