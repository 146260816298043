import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useGetDisciplineDivisions() {
	return useQuery<{ disciplines: Discipline[]; divisions: Division[] }>(
		['get-admin-panel-disciplines-divisions'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const { disciplines, divisions } = await graphQLClient.request(
				gql`
					query GetAllDisciplines {
						disciplines(order_by: { name: asc }) {
							id
							name
							color
							philosophy
							courses_disciplines {
								courses_disciplines_id
								course_id
								discipline_id
							}
						}
						divisions(order_by: { name: asc }) {
							id
							name
							courses_divisions {
								courses_divisions_id
								course_id
								division_id
							}
						}
					}
				`
			);
			return { disciplines, divisions };
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}

export function useGetAdminPanelCourses() {
	return useQuery(
		['get-admin-panel-courses'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);
			const { courses } = await graphQLClient.request(
				gql`
					query GetCourses {
						courses(order_by: { course_name: asc }) {
							course_id
							course_name
							grade
							is_ap
							is_archived
							course_discipline
							course_division
							courses_discipline {
								discipline {
									name
								}
							}
							courses_division {
								division {
									name
								}
							}
						}
					}
				`
			);
			return courses;
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}

interface UseCourseFragProps {
	courseId: string;
	courseName: string;
	disciplineId: string;
	divisionId: string;
	userRole: string;
	grade: string;
	isAp: boolean;
	isArchived: boolean;
}

export const useCourseMapFrag = async (variables: UseCourseFragProps) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const { userRole, courseId, disciplineId, divisionId, ...res } = variables;
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);
	const courseDisciplineMutation = gql`
		mutation UpdateCourseDiscipline($courseId: uuid!, $disciplineId: uuid!) {
			insert_courses_disciplines(
				objects: { discipline_id: $disciplineId, course_id: $courseId }
				on_conflict: {
					constraint: courses_disciplines_course_id_key
					update_columns: discipline_id
					where: { course_id: { _eq: $courseId } }
				}
			) {
				affected_rows
			}
		}
	`;
	await graphQLClient.request(courseDisciplineMutation, { disciplineId, courseId });

	const courseDivisionMutation = gql`
		mutation UpdateCourseDivision($courseId: uuid!, $divisionId: uuid!) {
			insert_courses_divisions(
				objects: { division_id: $divisionId, course_id: $courseId }
				on_conflict: {
					constraint: courses_divisions_course_id_key
					update_columns: division_id
					where: { course_id: { _eq: $courseId } }
				}
			) {
				affected_rows
			}
		}
	`;
	await graphQLClient.request(courseDivisionMutation, { divisionId, courseId });

	const courseMutation = gql`
		mutation UpdateCourse(
			$courseId: uuid!
			$division: String
			$courseName: String!
			$grade: String
			$isAp: Boolean
			$isArchived: Boolean
		) {
			update_courses(
				where: { course_id: { _eq: $courseId } }
				_set: {
					course_division: $division
					course_name: $courseName
					grade: $grade
					is_ap: $isAp
					is_archived: $isArchived
				}
			) {
				returning {
					course_id
					course_name
					course_division
					grade
					is_ap
					is_archived
					courses_discipline {
						discipline {
							name
						}
					}
					courses_division {
						division {
							name
						}
					}
				}
			}
		}
	`;

	return graphQLClient.request(courseMutation, { ...res, courseId });
};
