import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
interface queryCourseResult {
	queryKey: string[];
}

export async function useGetMapCourse(this: queryCourseResult) {
	graphQLClient.setHeader('content-type', `application/json`);

	const courseQuery = gql`
		query GetCourse($id: uuid!) {
			courses_by_pk(course_id: $id) {
				course_name
				course_id
				course_description
				course_resource_info
				course_extras
				course_prereq
				last_updated
				courses_outcomes(order_by: { outcome: { outcome_order: asc } }) {
					courses_outcomes_id
					outcome {
						outcome_order
						outcome_text
						outcome_id
					}
				}
				courses_resources(order_by: { resource: { resource_type: asc } }) {
					course_resources_id
					resource {
						resource_author
						resource_detail
						resource_isbn
						resource_id
						resource_title
						resource_type
					}
				}
				courses_focuses(order_by: { focus: { focus_order: asc, focus_type: desc } }) {
					courses_focuses_id
					focus {
						focus_title
						focus_type
						focus_order
						focus_id
						focuses_skills {
							focuses_skills_id
							skill {
								skill_id
								skill_text
								skill_type
							}
						}
					}
				}
			}
		}
	`;

	if (!this.queryKey?.[1]) return null;

	const { courses_by_pk: course } = await graphQLClient.request(courseQuery, {
		id: this.queryKey?.[1],
	});

	return course;
}

export const useSiteSettings = () => {
	graphQLClient.setHeader('content-type', `application/json`);

	return useQuery(
		['get-public-map-site-settings'],
		async () => {
			const { site_settings: settings, disciplines } = await graphQLClient.request(
				gql`
					query GetSiteSettings {
						site_settings {
							id
							statement
							statement_title
							primary_color
							banner
						}
						disciplines(order_by: { name: asc }) {
							id
							color
							name
						}
					}
				`
			);
			return { meta: settings[0], disciplines };
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
};
