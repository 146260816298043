/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, createContext, useCallback } from 'react';
import { hierarchy as d3Hierarchy } from 'd3-hierarchy';
import { tree as d3Tree } from 'd3';
import { group as d3Group } from 'd3-array';

import { Link, useLocation } from 'react-router-dom';
import Loader from '@/components/Loader';
import PrivacyTagLine from '@/components/PrivacyTagLine';
import { useAuth, useUserRole } from '@/hooks/useAuth';
import { CourseNodeBase } from '@/components/CourseMap/coursemap.types';
import { useAllAlignmentCourses, useCourseAlignment } from './AlignmentContainer';
import { StyledFadeWrapper } from './CourseAlignmentStyles';

interface IAlignmentContext {
	triggerModal(): void;
	setContextCourseId(courseId: string): void;
	isCourseOwner(): boolean;
	modalActive: boolean;
	currentCourseId: string;
	setAdminNavigation(): JSX.Element | null;
	mapData: any;
	userRoleData: any;
	courseHashMap: any;
	prunedAlignment: any;
	alignmentCourses: any;
	alignmentCoursesLoading: boolean;
	alignmentCoursesFetching: boolean;
}

const AlignmentContext = createContext({} as IAlignmentContext);

const AlignmentProvider = ({ ...props }) => {
	const { children } = props;
	const [currentCourseId, setCurrentCourse] = useState('');
	const [modalActive, setModalActive] = useState(false);
	const triggerModal = useCallback(() => {
		setModalActive(!modalActive);
	}, [modalActive]);
	const { user } = useAuth();

	const { pathname } = useLocation();
	const { data: userRoleData } = useUserRole();
	const { data: mapData } = useCourseAlignment();

	const {
		data: alignmentCourses,
		isInitialLoading: alignmentCoursesLoading,
		isFetching: alignmentCoursesFetching,
	} = useAllAlignmentCourses();

	const root = d3Hierarchy(mapData ?? [], (d: CourseNodeBase) => {
		return d.children;
	});
	const courseRefArray = d3Tree()(root).descendants();
	const courseNameList = courseRefArray.map((course: any) => course.data.name);
	const prunedAlignment = alignmentCourses?.filter((course: any) =>
		courseNameList.includes(course.course_name)
	);

	const courseHashMap: any = d3Group(courseRefArray, (d: any) => d?.data.tracker);
	function setAdminNavigation() {
		if (!user) {
			return null;
		}
		if (['dept', 'user', 'admin'].includes(userRoleData?.user_role)) {
			return <Link to="dashboard/courses">Dashboard</Link>;
		}
		return <Link to="/dashboard/courses">Dashboard</Link>;
	}

	function isCourseOwner() {
		if (pathname === '/') {
			return false;
		}

		const canEdit = userRoleData?.user_role === 'admin';
		return canEdit;
	}

	function setContextCourseId(courseId: string) {
		setCurrentCourse(courseId);
	}

	React.useEffect(() => {
		const abortController = new AbortController();
		return () => {
			abortController.abort();
		};
	}, []);

	if (alignmentCoursesLoading) {
		return <Loader />;
	}

	return (
		<AlignmentContext.Provider
			value={{
				setAdminNavigation,
				triggerModal,
				mapData,
				setContextCourseId,
				isCourseOwner,
				currentCourseId,
				modalActive,
				userRoleData,
				courseHashMap,
				prunedAlignment,
				alignmentCourses,
				alignmentCoursesLoading,
				alignmentCoursesFetching,
			}}>
			<StyledFadeWrapper delay={0}>
				<>
					{children}
					<PrivacyTagLine />
				</>
			</StyledFadeWrapper>
		</AlignmentContext.Provider>
	);
};

export default AlignmentContext;

export { AlignmentProvider };
