import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

// ? Remove if CourseMapParent component is deleted
export function useGetPublicCourseMap() {
	return useQuery(
		['get-public-course-map'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const { course_map_by_pk: map } = await graphQLClient.request(
				gql`
					query GetCourseMap {
						course_map(where: { isDraft: { _eq: false } }) {
							course_map_json
						}
					}
				`
			);
			return JSON.parse(map.course_map_json);
		},
		{
			staleTime: Infinity,
		}
	);
}

export async function useGetCourseMap() {
	graphQLClient.setHeader('content-type', `application/json`);
	const courseQuery = gql`
		query GetCourseMap {
			course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;
	const { course_map: map } = await graphQLClient.request(courseQuery);

	return map[0]?.course_map_json ?? '{}';
}

// TODO: allow multiple entries for versioning
export const useMapUpdateFrag = async (variables: { map: string }) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	const { map } = variables;

	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', 'admin');

	const updateMutation = gql`
		mutation UpdateCourseMap($map: String!) {
			update_course_map(where: { isDraft: { _eq: false } }, _set: { course_map_json: $map }) {
				affected_rows
			}
		}
	`;

	const createMutation = gql`
		mutation UpdateCourseMap($map: String!) {
			insert_course_map(objects: { isDraft: false, course_map_json: $map }) {
				affected_rows
			}
		}
	`;

	const results = await graphQLClient.request(updateMutation, { map });

	// Runs on the first time the map is updated
	if (results.update_course_map.affected_rows === 0) {
		return graphQLClient.request(createMutation, { map });
	}

	return results;
};

export const useDeleteCourseFrag = async (variables: { id: string; userRole: string }) => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const { userRole, id } = variables;
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', userRole);
	const mutation = gql`
		mutation DeleteCourse($id: uuid!) {
			delete_courses(where: { course_id: { _eq: $id } }) {
				affected_rows
			}
		}
	`;
	return graphQLClient.request(mutation, { id });
};
