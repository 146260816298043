import { create } from 'zustand';

export interface Details {
	showApOnly: boolean;
	showDescription: boolean;
	showOutcomes: boolean;
	showResources: boolean;
	showUnits: boolean;
}
interface State {
	disciplines: Discipline[];
	updateDisciplines: (disciplines: any) => void;
	divisions: Division[];
	updateDivisions: (divisions: any) => void;
	details: Details;
	toggleBoolean: (key: keyof State['details']) => void;
	toggleAllDisciplines: () => void;
}

const markSelected = (array: Discipline[] | Division[], reference: Discipline | Division) => {
	return [...array].map((obj) => {
		if (obj.id === reference.id) {
			return { ...obj, isSelected: !obj.isSelected };
		}

		return obj;
	});
};

export const useAlignmentStore = create<State>((set, get) => ({
	disciplines: [],
	updateDisciplines: (discipline) => {
		const { disciplines } = get();

		const updatedDisciplines = markSelected(disciplines, discipline) as Discipline[];

		set({ disciplines: updatedDisciplines });
	},
	divisions: [],
	updateDivisions: (division) => {
		const { divisions } = get();

		const updatedDivisions = markSelected(divisions, division) as Division[];

		set({ divisions: updatedDivisions });
	},
	details: {
		showApOnly: false,
		showDescription: true,
		showOutcomes: true,
		showResources: true,
		showUnits: true,
	},
	toggleBoolean: (value) => {
		const flag = get().details[value];

		set({ details: { ...get().details, [value]: !flag } });
	},
	toggleAllDisciplines: () => {
		const { disciplines } = get();

		const allFalse = disciplines.every((d) => !d.isSelected);

		set({
			disciplines: disciplines.map((d) => ({ ...d, isSelected: allFalse })),
		});
	},
}));
