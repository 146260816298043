/* eslint-disable no-await-in-loop */
import { getAuth } from 'firebase/auth';

import { gql, GraphQLClient, request } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SetStatus } from '@/Enums/enum';
import GlobalContext from '@/context/GlobalContext';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
const removeCommentTags = (text: string) => {
	const regex =
		/<i(?=\s)(?!(?:[^>"\\']|"[^"]*"|\\'[^\\']*\\')*?(?<=\s)(?:term|range)\s*=)(?!\s*>)\s+(?:".*?"|\\'.*?\\'|[^>]*?)+>|<\/i>/gm;

	return text.replace(regex, '');
};

export async function massApproveSubmissions(querySubmissionData: CourseSubmission[]) {
	const query = gql`
		query GetCurrentCourses($courseId: uuid!) {
			courses_by_pk(course_id: $courseId) {
				course_name
				course_id
				course_description
				course_resource_info
				course_prereq
				course_extras
				course_division
				submission_id
				course_draft
				is_saved_draft
				last_updated
				submission {
					admin_approval
					dept_approval
				}
				courses_outcomes {
					courses_outcomes_id
					outcome {
						outcome_order
						outcome_text
						outcome_id
					}
				}
				courses_resources {
					course_resources_id
					resource {
						resource_author
						resource_detail
						resource_isbn
						resource_id
						resource_title
						resource_type
					}
				}
				courses_focuses {
					courses_focuses_id
					focus {
						focus_title
						focus_type
						focus_order
						focus_id
						focuses_skills {
							focuses_skills_id
							skill {
								skill_id
								skill_text
								skill_type
							}
						}
					}
				}
			}
		}
	`;
	const publishedOutcomeLen = querySubmissionData?.length;

	for (let i = 0; i < publishedOutcomeLen; i += 1) {
		const { course } = querySubmissionData[i];
		const courseId = course.course_id;
		await request(`${import.meta.env.VITE_HASURA_ENDPOINT}`, query, {
			courseId,
		}).then(async (data) => {
			const { courses_by_pk: course } = data;
			const { submission } = course;
			if (
				submission.admin_approval === SetStatus.APPROVED &&
				submission.dept_approval === SetStatus.APPROVED
			) {
				return true;
			}

			const publishedCourse = course;
			const draftCourse = JSON.parse(removeCommentTags(course.course_draft) ?? '{}');

			const deleteOutcomesMutation = gql`
				mutation deleteOutcomesMutation($outcomeId: uuid!) {
					delete_outcomes(where: { outcome_id: { _eq: $outcomeId } }) {
						affected_rows
					}
				}
			`;
			const createOutcomeMutation = gql`
				mutation OutcomeMutation($outcomeText: String, $outcomeOrder: Int) {
					insert_outcomes_one(
						object: { outcome_text: $outcomeText, outcome_order: $outcomeOrder }
					) {
						outcome_id
					}
				}
			`;
			const createCourseOutcomeMutation = gql`
				mutation CourseOutcomeMutation($courseId: uuid, $outcomeId: uuid) {
					insert_courses_outcomes(
						objects: { course_id: $courseId, outcome_id: $outcomeId }
					) {
						affected_rows
					}
				}
			`;

			async function deletePublishedOutcomes() {
				const promises = [];
				const publishedOutcomeLen = publishedCourse.courses_outcomes?.length;
				for (let i = 0; i < publishedOutcomeLen; i += 1) {
					const { outcome } = publishedCourse.courses_outcomes[i];
					const { outcome_id: outcomeId } = outcome;
					if (outcomeId) {
						promises.push(
							graphQLClient.request(deleteOutcomesMutation, {
								outcomeId,
							})
						);
					}
				}
				await Promise.all(promises);
			}

			if (publishedCourse.courses_outcomes?.length > 0) {
				await deletePublishedOutcomes();
			}

			async function publishDraftOutcomes() {
				const promises = [];
				const draftOutcomeLen = draftCourse.courses_outcomes?.length;
				for (let i = 0; i < draftOutcomeLen; i += 1) {
					const { outcome } = draftCourse.courses_outcomes[i];
					const { outcome_text: outcomeText, outcome_order: outcomeOrder = 0 } = outcome;
					const { insert_outcomes_one: insertedOutcome } = await graphQLClient.request(
						createOutcomeMutation,
						{
							outcomeText,
							outcomeOrder,
						}
					);
					promises.push(
						graphQLClient.request(createCourseOutcomeMutation, {
							outcomeId: insertedOutcome.outcome_id,
							courseId,
						})
					);
				}
				await Promise.all(promises);
			}

			if (draftCourse?.courses_outcomes?.length > 0) {
				await publishDraftOutcomes();
			}

			const createResourceMutation = gql`
				mutation ResourceMutation(
					$resourceAuthor: String
					$resourceDetail: String
					$resourceIsbn: String
					$resourceType: String
					$resourceTitle: String
				) {
					insert_resources_one(
						object: {
							resource_author: $resourceAuthor
							resource_detail: $resourceDetail
							resource_isbn: $resourceIsbn
							resource_type: $resourceType
							resource_title: $resourceTitle
						}
					) {
						resource_id
					}
				}
			`;
			const createCourseResourceMutation = gql`
				mutation CourseResourceMutation($courseId: uuid, $resourceId: uuid) {
					insert_courses_resources(
						objects: { course_id: $courseId, resource_id: $resourceId }
					) {
						affected_rows
					}
				}
			`;
			const deleteResourceMutation = gql`
				mutation DeleteResourceMutation($resourceId: uuid!) {
					delete_resources(where: { resource_id: { _eq: $resourceId } }) {
						affected_rows
					}
				}
			`;

			async function deletePublishedResources() {
				const promises = [];
				const publishedResourcesLen = publishedCourse.courses_resources?.length;
				for (let i = 0; i < publishedResourcesLen; i += 1) {
					const { resource } = publishedCourse.courses_resources[i];
					const { resource_id: resourceId } = resource;
					if (resourceId) {
						promises.push(
							graphQLClient.request(deleteResourceMutation, {
								resourceId,
							})
						);
					}
				}
				await Promise.all(promises);
			}

			if (publishedCourse.courses_resources?.length > 0) {
				await deletePublishedResources();
			}

			async function publishDraftResources() {
				const promises = [];
				const draftResourcesLen = draftCourse.courses_resources?.length;
				for (let i = 0; i < draftResourcesLen; i += 1) {
					const { resource } = draftCourse.courses_resources[i];
					const {
						resource_author: resourceAuthor,
						resource_detail: resourceDetail,
						resource_isbn: resourceIsbn,
						resource_type: resourceType,
						resource_title: resourceTitle,
					} = resource;
					const { insert_resources_one: insertedResource } = await graphQLClient.request(
						createResourceMutation,
						{
							resourceAuthor,
							resourceDetail,
							resourceIsbn,
							resourceType,
							resourceTitle,
						}
					);
					promises.push(
						graphQLClient.request(createCourseResourceMutation, {
							resourceId: insertedResource.resource_id,
							courseId,
						})
					);
				}
				await Promise.all(promises);
			}

			if (draftCourse?.courses_resources?.length > 0) {
				await publishDraftResources();
			}

			const createFocusMutation = gql`
				mutation FocusMutation($focusTitle: String, $focusOrder: Int) {
					insert_focuses_one(
						object: { focus_title: $focusTitle, focus_order: $focusOrder }
					) {
						focus_id
					}
				}
			`;
			const createSkillMutation = gql`
				mutation SkillMutation($skillText: String, $skillType: String) {
					insert_skills_one(object: { skill_text: $skillText, skill_type: $skillType }) {
						skill_id
					}
				}
			`;
			const createFocusSkillMutation = gql`
				mutation FocusSkillMutation($skillId: uuid, $focusId: uuid) {
					insert_focuses_skills_one(object: { skill_id: $skillId, focus_id: $focusId }) {
						focuses_skills_id
					}
				}
			`;
			const deleteFocusMutation = gql`
				mutation DeleteFocusMutation($focusId: uuid!) {
					delete_focuses(where: { focus_id: { _eq: $focusId } }) {
						affected_rows
					}
				}
			`;
			const deleteSkillMutation = gql`
				mutation DeleteSkillMutation($skillId: uuid!) {
					delete_skills(where: { skill_id: { _eq: $skillId } }) {
						affected_rows
					}
				}
			`;
			const deleteFocusSkillMutation = gql`
				mutation DeleteSkillMutation($focusSkillId: uuid!) {
					delete_focuses_skills(where: { focuses_skills_id: { _eq: $focusSkillId } }) {
						affected_rows
					}
				}
			`;
			const createCourseFocusesMutation = gql`
				mutation CourseFocusesMutation($courseId: uuid, $focusId: uuid) {
					insert_courses_focuses_one(
						object: { course_id: $courseId, focus_id: $focusId }
					) {
						focus_id
					}
				}
			`;

			async function deletePublishedFocuses() {
				const promises = [];
				const publishedFocusLen = publishedCourse.courses_focuses?.length;
				for (let i = 0; i < publishedFocusLen; i += 1) {
					const { focus } = publishedCourse.courses_focuses[i];
					const skillLen = focus?.focuses_skills?.length;
					for (let sIdx = 0; sIdx < skillLen; sIdx += 1) {
						const skill = focus.focuses_skills[sIdx]?.skill;
						const focusSkillId = focus.focuses_skills[sIdx].focuses_skills_id;
						if (focusSkillId) {
							promises.push(
								graphQLClient.request(deleteFocusSkillMutation, {
									focusSkillId,
								})
							);
						}
						if (skill) {
							const skillId = skill.skill_id;
							promises.push(
								graphQLClient.request(deleteSkillMutation, {
									skillId,
								})
							);
						}
					}
					const { focus_id: focusId } = focus;
					if (focusId) {
						promises.push(
							graphQLClient.request(deleteFocusMutation, {
								focusId,
							})
						);
					}
				}
				await Promise.all(promises);
			}

			if (publishedCourse.courses_focuses?.length > 0) {
				await deletePublishedFocuses();
			}

			async function publishDraftFocuses() {
				const promises = [];
				const draftFocusLen = draftCourse.courses_focuses?.length;
				for (let i = 0; i < draftFocusLen; i += 1) {
					const { focus } = draftCourse.courses_focuses[i];
					const { focus_order: focusOrder, focus_title: focusTitle } = focus;
					const { insert_focuses_one: insertedFocus } = await graphQLClient.request(
						createFocusMutation,
						{
							focusTitle,
							focusOrder,
						}
					);
					promises.push(
						await graphQLClient.request(createCourseFocusesMutation, {
							focusId: insertedFocus.focus_id,
							courseId,
						})
					);
					const skillLen = focus?.focuses_skills?.length;
					for (let sIdx = 0; sIdx < skillLen; sIdx += 1) {
						const skill = focus.focuses_skills[sIdx]?.skill;
						if (skill) {
							const { skill_text: skillText, skill_type: skillType } = skill;
							const skillDefaultType =
								skill.skill_type === '' || skill.skill_type === null
									? 'topic'
									: skillType;
							const { insert_skills_one: insertedSkill } =
								await graphQLClient.request(createSkillMutation, {
									skillText,
									skillType: skillDefaultType,
								});
							promises.push(
								await graphQLClient.request(createFocusSkillMutation, {
									skillId: insertedSkill.skill_id,
									focusId: insertedFocus.focus_id,
								})
							);
						}
					}
				}
				await Promise.all(promises);
			}

			if (draftCourse?.courses_focuses?.length > 0) {
				await publishDraftFocuses();
			}

			const courseBasicMutation = gql`
				mutation updateCourse(
					$courseId: uuid!
					$courseDescription: String
					$courseExtras: String
					$coursePrereq: String
					$courseResourceInfo: String
				) {
					update_courses_by_pk(
						pk_columns: { course_id: $courseId }
						_set: {
							course_description: $courseDescription
							course_resource_info: $courseResourceInfo
							course_extras: $courseExtras
							course_prereq: $coursePrereq
						}
					) {
						course_id
					}
				}
			`;
			const {
				course_description: courseDescription,
				course_resource_info: courseResourceInfo,
				course_extras: courseExtras,
				course_prereq: coursePrereq,
			} = draftCourse;

			await graphQLClient.request(courseBasicMutation, {
				courseId,
				courseDescription,
				courseResourceInfo,
				courseExtras,
				coursePrereq,
			});

			const queryCurrentCourse = gql`
				query currentCourse($courseId: uuid!) {
					courses_by_pk(course_id: $courseId) {
						course_name
						course_id
						course_description
						course_resource_info
						course_prereq
						course_extras
						course_division
						course_draft
						courses_outcomes {
							courses_outcomes_id
							outcome {
								outcome_order
								outcome_text
								outcome_id
							}
						}
						courses_resources {
							course_resources_id
							resource {
								resource_author
								resource_detail
								resource_isbn
								resource_id
								resource_title
								resource_type
							}
						}
						courses_focuses {
							courses_focuses_id
							focus {
								focus_title
								focus_type
								focus_order
								focus_id
								focuses_skills {
									focuses_skills_id
									skill {
										skill_id
										skill_text
										skill_type
									}
								}
							}
						}
					}
				}
			`;
			const { courses_by_pk: refetchedCourse } = await graphQLClient.request(
				queryCurrentCourse,
				{
					courseId,
				}
			);
			const newDraft = JSON.stringify(refetchedCourse);
			const courseNewDraftMutation = gql`
				mutation newDraftMutation($courseDraft: String!, $courseId: uuid!) {
					update_courses_by_pk(
						pk_columns: { course_id: $courseId }
						_set: { course_draft: $courseDraft }
					) {
						course_id
					}
				}
			`;
			await graphQLClient.request(courseNewDraftMutation, {
				courseDraft: newDraft,
				courseId,
			});

			const courseMutation = gql`
				mutation updateCourse {
					update_submissions(
						where: { course_id: { _eq: "${courseId}" } }
						_set: { admin_approval: ${SetStatus.APPROVED}, dept_approval: ${SetStatus.APPROVED} }
					) {
						returning {
							admin_approval
							dept_approval
						}
					}
				}
			`;

			return graphQLClient.request(courseMutation);
		});
	}
}

export function useGetAllSubmissions() {
	const { user } = { ...useContext(GlobalContext) };

	return useQuery(
		['get-all-subs'],
		async () => {
			const fid = await (user as any)?.uid;
			graphQLClient.setHeader('content-type', `application/json`);

			const query = gql`
				query GetCurrentUser($id: String) {
					users(where: { user_firebase_id: { _eq: $id } }) {
						user_role
						user_id
					}
				}
			`;

			const { users } = await graphQLClient.request(query, { id: fid });
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('x-hasura-role', users[0].user_role);
			const uid = users[0].user_id;

			if (users[0].user_role === 'admin') {
				const { submissions } = await graphQLClient.request(
					gql`
						query GetSubmissions($fid: String) {
							submissions(order_by: { course: { course_name: asc } }) {
								submission_id
								admin_approval
								dept_approval
								course {
									course_name
									course_id
									courses_users {
										user_id
										course_id
										user {
											user_id
											user_first
											user_last
											user_firebase_id
											dept_chair {
												user_firebase_id
												user_id
											}
										}
									}
								}
							}
						}
					`
				);
				return submissions;
			}

			if (users[0].user_role === 'dept') {
				const { submissions } = await graphQLClient.request(
					gql`
                    query GetSubmissions($fid: String, $deptId: String) {
                        submissions(
                            order_by: { course: { course_name: asc } }
                            where: {
                                _or: [
                                    {
                                        course: {
                                            courses_users: {user_id: {_eq: "${uid}"}}
                                        }
                                    }
                                    {
                                        course: {
                                            courses_users: {user: {dept_chair: {user_id: {_eq: "${uid}"}}}}
                                        }
                                    }
                                ]
                            }
                        ) {
                            submission_id
                            admin_approval
                            dept_approval
                            course {
                                course_name
                                course_id
                                courses_users {
                                    user_id
                                    course_id
                                    user {
                                        user_id
                                        user_first
                                        user_last
                                        user_firebase_id
                                        dept_chair {
                                            user_firebase_id
                                            user_id
                                        }
                                    }
                                }
                            }
                        }
                    }
                `
				);
				return submissions;
			}

			const { submissions } = await graphQLClient.request(
				gql`
                query GetSubmissions {
                    submissions(
                        order_by: { course: { course_name: asc } }
                        where: {
                            course: {
                                courses_users: {
                                    user_id: {_eq: "${uid}"}
                                }
                            }
                        }
                    ) {
                        submission_id
                        admin_approval
                        dept_approval
                        course {
                            course_name
                            course_id
                            courses_users {
                                user_id
                                course_id
                                user {
                                    user_id
                                    user_first
                                    user_last
                                    user_firebase_id
                                    dept_chair {
                                        user_firebase_id
                                        user_id
                                    }
                                }
                            }
                        }
                    }
                }
            `
			);

			return submissions;
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}

// TODO: add button to remove all submissions
// ? query to remove all submissions
// mutation RemoveAllSubmissions {
// 	update_courses(where: {submission_id: {_is_null: false}}, _set: {submission_id: null}) {
// 	  affected_rows
// 	}
// 	delete_submissions(where: {}) {
// 	  affected_rows
// 	}
//   }
