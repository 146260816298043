/* eslint-disable react/jsx-no-constructed-context-values */
import {
	useState,
	useEffect,
	createContext,
	useCallback,
	useRef,
	Fragment,
	useContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import emailjs from 'emailjs-com';
import { getAuth } from 'firebase/auth';
import { Editor } from '@tiptap/core';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql, GraphQLClient } from 'graphql-request';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { useCurrentUser, useUserRole } from '@/hooks/useAuth';
import { EditorType, SetStatus } from '@/Enums/enum';
import { DropResultEntity } from '@/types/source';
import { useCourseLessonsStore } from '@/stores/courseEditStore';
import GlobalContext from '@/context/GlobalContext';
import { createEmailSubmissionLink } from '@/utils';
import {
	existingSub,
	setDraftFrag,
	updateDraftFrag,
	updateLessonPlans,
	updateSubStatusFrag,
	useGetAllAdmin,
	useGetCurrentCourses,
} from './CourseEditContainers';

interface ICourseEditContext {
	addFocus(): void;
	addOutcome(): void;
	addOutcomeTag(a?: any): void;
	addResource(a: any): void;
	addSkill(a: string, b: string): void;
	canEdit: boolean;
	courseData: Course;
	courseDraftData: Course;
	currentUserData: any;
	editorRefs: React.MutableRefObject<Map<string, Editor>>;
	isAlignment: boolean;
	isApprovalMode: boolean;
	isCourseOwner(a: any, b: any): void;
	isFetched: boolean;
	isFetchedAfterMount: boolean;
	isPendingApproval(): boolean;
	lessonPlans: any;
	removeFocus(a: any): void;
	removeOutcome(a: any): void;
	removeResource(a: any): void;
	removeSkill(a: any): void;
	saveDraft(): Promise<void>;
	submitCourse(): void;
	updateAdditionalInfo(a: any): void;
	updateCourseReducer(a: any, b: any): void;
	updateDescription(a: any): void;
	updateDraftStatus(a: any, b?: any): void;
	updateEditorRef(key: string, editor: Editor): void;
	updateFocus(a: any): void;
	updateFocusOrder(a: any, b: any): void;
	updateOutcome(a: any): void;
	updateOutcomeOrder(a: any, b: any): void;
	updatePrereq(a: any): void;
	updateResourceInfo(a: any): void;
	updateResources(a: any): void;
	updatePlans(plan: LessonSection[], courseId: string): void;
}

interface CourseUrlParams {
	[key: string]: string;
}

const CourseEditContext = createContext({} as ICourseEditContext);

const CourseEditProvider = ({ ...props }: any) => {
	const { children } = props;
	const { pathname } = useLocation();

	const isAlignment = pathname.includes('course-alignment');
	const { data: adminUsers } = useGetAllAdmin();
	const { currentUser } = useContext(GlobalContext);

	const [courseData, setCourseData] = useState<any>();
	const [courseDraftData, setCourseDraftData] = useState<any>({});
	const { data: userRoleData } = useUserRole();
	const [searchParams] = useSearchParams();
	const isApprovalMode = !!searchParams.get('isApprovalMode');
	const params = useParams<CourseUrlParams>();

	const {
		data: courseInfo,
		isFetchedAfterMount,
		isFetched: isCourseFetched,
	} = useGetCurrentCourses(userRoleData, params.courseId);

	const isFetched = isCourseFetched;
	const [courseSubId, setCourseSubId] = useState('');
	const subIdRef = useRef(courseData?.submission_id ?? '');
	// TODO: look into
	// ?? can I use current user instead?
	const { data: currentUserData } = useCurrentUser();
	const updateDescription = useCallback((editorData: string) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			course_description: editorData,
		}));
	}, []);

	const editorRefs = useRef(new Map<string, Editor>());

	const updateEditorRef = (key: string, editor: Editor) => {
		editorRefs.current.set(key, editor);
	};

	function isCourseOwner(courseUsers: CourseUserEntity[], user: User) {
		const canEdit =
			courseUsers.some((c) => c?.user?.user_id === user?.user_id) ||
			user?.user_role === 'admin';
		const isDept = courseUsers.some((c) => c?.user?.dept_chair?.user_id === user?.user_id);

		return isDept || canEdit;
	}

	const canEdit = isCourseOwner(courseData?.courses_users ?? [], currentUserData);

	const isPendingApproval = useCallback(() => {
		if (courseInfo?.submission) {
			return Object.values(courseInfo.submission).every(
				(status) => status !== SetStatus.APPROVED
			);
		}
		return false;
	}, [courseInfo?.submission]);

	const updateResourceInfo = useCallback((editorData: string) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			course_resource_info: editorData,
		}));
	}, []);

	const updatePrereq = useCallback((inputVal: string) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			course_prereq: inputVal,
		}));
	}, []);

	const updateAdditionalInfo = useCallback((editorData: string) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			course_extras: editorData,
		}));
	}, []);

	const addSubmission = async (variables: { courseId: string }) => {
		const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
		const auth = getAuth();
		const token = await auth.currentUser?.getIdToken();
		const graphQLClient = new GraphQLClient(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`,
				'x-hasura-role': userRoleData?.user_role,
			},
		});
		const subMutation = gql`
			mutation AddNewSubmissions($courseId: uuid!) {
				insert_submissions_one(object: { course_id: $courseId }) {
					submission_id
				}
			}
		`;

		const { insert_submissions_one: subInsert } = await graphQLClient.request(
			subMutation,
			variables
		);

		const courseSubIdMutation = gql`
			mutation UpdateCourseSubId($courseId: uuid!, $subId: uuid!) {
				update_courses(
					where: { course_id: { _eq: $courseId } }
					_set: { submission_id: $subId }
				) {
					affected_rows
				}
			}
		`;

		subIdRef.current = subInsert?.submission_id;
		setCourseSubId(subInsert?.submission_id);
		const subVars = {
			courseId: variables.courseId,
			subId: subInsert?.submission_id,
		};

		return graphQLClient.request(courseSubIdMutation, subVars);
	};

	const updateDraft = async (variables: {
		courseId: string;
		courseDraft: string;
		isDraft?: boolean;
	}) =>
		updateDraftFrag({
			variables,
			userRole: userRoleData?.user_role,
		});

	const queryClient = useQueryClient();

	const setDraftStatus = async (variables: {
		courseId: string;
		subId: string;
		status: string;
		approveAll: boolean;
	}) =>
		setDraftFrag({
			variables,
			draftCourse: courseDraftData,
			publishedCourse: courseData,
			userRole: userRoleData?.user_role,
		});

	const { mutate: updateDraftStatusMutate } = useMutation(setDraftStatus, {
		mutationKey: ['submission-status'],
		onSuccess: (_, variables: any) => {
			const { status } = variables;

			const { course_name: courseName } = courseData;
			// const previousCourseData = queryClient.getQueryData([
			// 	'get-current-course',
			// ]) as Partial<Course>;

			// previousCourseData.is_saved_draft = false;
			// previousCourseData.submission = {
			// 	admin_approval: SetStatus.APPROVED,
			// 	dept_approval: SetStatus.APPROVED,
			// };

			// update query cache
			// queryClient.setQueryData('get-current-course', previousCourseData);

			// send email to course owner/s when status changes
			// statuses: approved or changes requested
			courseData.courses_users?.forEach((user: Partial<CourseUserEntity>) => {
				if (!user.user) return;
				const { user_email: email } = user.user;

				if (import.meta.env.MODE !== 'development' && email) {
					emailjs.send(
						`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
						'template_0b5w8ea',
						{
							courseName,
							emailRecipient: email,
							courseUrl: createEmailSubmissionLink(window.location.href),
						},
						`${import.meta.env.VITE_EMAILJS_USER_ID}`
					);
				}
			});

			queryClient.invalidateQueries(['get-all-courses']);
			queryClient.invalidateQueries(['get-current-course']);
			queryClient.invalidateQueries(['get-all-subs']);
			queryClient.refetchQueries({ queryKey: ['get-all-alignment-courses'] });

			if (status === 'rejected') {
				showSuccessToast(`Course Draft Changes Requested`);
			} else {
				showSuccessToast(`Course Draft Approved`);
			}
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updateDraftStatus = (status: string, approveAll = false) => {
		const subId = courseSubId ?? courseData?.submission_id;
		const { course_id: courseId } = courseData;
		updateDraftStatusMutate({ courseId, subId, status, approveAll });
	};

	const { mutate: plansMutate } = useMutation(updateLessonPlans, {
		mutationKey: ['save-course-lesson'],
		onSuccess: () => {
			showSuccessToast('Lesson Plans Saved');
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updatePlans = (plans: LessonSection[], courseId: string) => {
		plansMutate({ plans, courseId });
	};

	const { mutate: saveDraftMutate } = useMutation(updateDraft, {
		mutationKey: ['save-draft-status'],
		onSuccess: () => {
			showSuccessToast('Course Draft Saved');

			queryClient.invalidateQueries(['get-all-courses']);
			const previousCourseData = queryClient.getQueryData([
				'get-current-course',
			]) as Partial<Course>;

			previousCourseData.is_saved_draft = true;
			// update query cache
			queryClient.setQueryData(['get-current-course'], previousCourseData);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutate: draftMutate } = useMutation(updateDraft, {
		mutationKey: ['draft-status'],
		onSuccess: (results: any) => {
			queryClient.invalidateQueries(['get-all-courses']);

			const previousCourseData = queryClient.getQueryData([
				'get-current-course',
			]) as Partial<Course>;

			previousCourseData.is_saved_draft = false;
			previousCourseData.submission = {
				admin_approval: SetStatus.WAITING,
				dept_approval: SetStatus.WAITING,
			};
			// update query cache
			queryClient.setQueryData(['get-current-course'], previousCourseData);
			const isSubmitted = results?.update_courses_by_pk?.submission_id;
			subIdRef.current = isSubmitted;
			setCourseSubId(isSubmitted);
			if (isSubmitted) {
				showSuccessToast('Course Draft Resubmitted');
			} else {
				showSuccessToast('Course Draft Submitted');
			}

			const { course_name: courseName } = courseData;
			const firstName = currentUser?.user_first ?? '';
			const lastName = currentUser?.user_last ?? '';
			const submitterName = `${firstName} ${lastName}`;

			// send email to dept chair when user submits course for review
			if (currentUser?.dept_chair?.user_email && process.env.NODE_ENV !== 'development') {
				emailjs.send(
					`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
					'template_51oe9lq',
					{
						submitterName,
						courseName,
						courseUrl: createEmailSubmissionLink(window.location.href),
						emailRecipient: currentUser.dept_chair.user_email,
					},
					`${import.meta.env.VITE_EMAILJS_USER_ID}`
				);
			}

			// send email to all admins when course is submitted
			if (process.env.NODE_ENV !== 'development') {
				emailjs.send(
					`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
					'template_51oe9lq',
					{
						submitterName,
						courseName,
						courseUrl: createEmailSubmissionLink(window.location.href),
						emailRecipient: adminUsers
							?.map((user: Partial<User>) => user.user_email)
							.filter(
								(email: string) =>
									email !== currentUser?.dept_chair?.user_email &&
									email !== currentUser?.user_email
							)
							.toString(),
					},
					`${import.meta.env.VITE_EMAILJS_USER_ID}`
				);
			}

			queryClient.invalidateQueries(['get-all-subs']);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updateSubStatus = async (variables: { courseId: string }) =>
		updateSubStatusFrag({
			variables,
			userRole: userRoleData?.user_role,
		});

	const getSubId = useCallback(
		async (variables: { courseId: string }) =>
			existingSub({
				variables,
				userRole: userRoleData?.user_role,
			}),
		[userRoleData?.user_role]
	);

	const { mutate: subStatusMutate } = useMutation(updateSubStatus, {
		onSuccess: () => {
			queryClient.invalidateQueries(['get-all-subs']);
			queryClient.invalidateQueries(['get-current-course']);
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutate: subMutate } = useMutation(addSubmission, {
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const saveDraft = useCallback(async () => {
		const clone = { ...courseDraftData };
		const { course_id: courseId } = courseData;

		saveDraftMutate({ courseId, courseDraft: JSON.stringify(clone), isDraft: true });
	}, [courseDraftData, saveDraftMutate, courseData]);

	// TODO: implement auto save
	// useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		// saveDraft();
	// 	}, 10000);
	// 	return () => clearTimeout(timer);
	// }, [saveDraft]);

	const submitCourse = useCallback(async () => {
		const { course_id: courseId } = courseData;
		const hasSubmission = await getSubId({ courseId });

		if (hasSubmission) {
			subStatusMutate({ courseId });
			const clone = { ...courseDraftData };
			delete clone.course_draft;
			draftMutate({ courseId, courseDraft: JSON.stringify(clone) });
		} else {
			const clone = { ...courseDraftData };
			delete clone.course_draft;
			draftMutate({ courseId, courseDraft: JSON.stringify(clone) });
			subMutate({ courseId });
		}
	}, [courseData, subMutate, courseDraftData, draftMutate, subStatusMutate, getSubId]);

	const updateOutcomeOrder = (outcomeArr: CoursesOutcomesEntity[], result: DropResultEntity) => {
		setCourseDraftData((prevState: Course) => {
			const { destination, source } = result;
			if (!destination) {
				return { ...prevState };
			}

			if (
				destination.droppableId === source.droppableId &&
				destination.index === source.index
			) {
				return { ...prevState };
			}

			const sortedArr = outcomeArr.sort(
				(a: CoursesOutcomesEntity, b: CoursesOutcomesEntity) =>
					a.outcome.outcome_order - b.outcome.outcome_order
			);

			const match = sortedArr.splice(source.index, 1)[0];
			sortedArr.splice(destination.index, 0, match);

			const updatedOutcomes = outcomeArr.map(
				(outcome: CoursesOutcomesEntity, index: number) => {
					outcome.outcome.outcome_order = index;
					return outcome;
				}
			);

			return { ...prevState, courses_outcomes: updatedOutcomes };
		});
	};

	const updateFocusOrder = (focusArr: CourseFocusEntity[], result: DropResultEntity) => {
		setCourseDraftData((prevState: Course) => {
			const { destination, source } = result;
			if (!destination) {
				return { ...prevState };
			}

			if (
				destination.droppableId === source.droppableId &&
				destination.index === source.index
			) {
				return { ...prevState };
			}

			const sortedArr = focusArr.sort(
				(a: CourseFocusEntity, b: CourseFocusEntity) =>
					a.focus.focus_order - b.focus.focus_order
			);

			const match = sortedArr.splice(source.index, 1)[0];
			sortedArr.splice(destination.index, 0, match);

			const updatedFocuses = focusArr.map((focus: CourseFocusEntity, index: number) => {
				focus.focus.focus_order = index;
				return focus;
			});

			return { ...prevState, courses_focuses: updatedFocuses };
		});
	};

	const removeOutcome = (id: string) => {
		setCourseDraftData((prevState: Course) => {
			const { courses_outcomes: courseOutcome } = prevState;

			const newOutcomesArray = courseOutcome?.filter(
				(outcome: CoursesOutcomesEntity) => outcome.courses_outcomes_id !== id
			);

			return { ...prevState, courses_outcomes: newOutcomesArray };
		});
	};

	const updateOutcome = useCallback((data: any) => {
		setCourseDraftData((prevState: Course) => {
			const { id, debouncedText } = data;
			const { courses_outcomes: courseOutcome } = prevState;

			const clone = [...(courseOutcome ?? [])];
			const updatedOutcomes = clone.map((outcome: CoursesOutcomesEntity) => {
				if (outcome.outcome.outcome_id === id) {
					outcome.outcome.outcome_text = debouncedText;
					return outcome;
				}
				return outcome;
			});

			return {
				...prevState,
				courses_outcomes: updatedOutcomes,
			};
		});
	}, []);

	const addOutcomeTag = (obj: any) => {
		const isChecked = obj.e.target.checked;
		if (isChecked) {
			setCourseDraftData((prevState: Course) => {
				const { course_tag: courseTag } = prevState;
				const newTag = { courses_tags_id: '', tag: obj.tag };
				const tagList = [...(courseTag ?? []), newTag];

				return { ...prevState, course_tag: tagList };
			});
		} else {
			setCourseDraftData((prevState: Course) => {
				const { course_tag: courseTag } = prevState;
				const selectedTag = courseTag?.filter((t: any) => t.tag.tag_id !== obj.tag.tag_id);

				return { ...prevState, course_tag: selectedTag };
			});
		}
	};

	const addOutcome = () => {
		setCourseDraftData((prevState: Course) => {
			const { courses_outcomes: courseOutcome } = prevState;
			const newOutcomesArray = [
				...(courseOutcome ?? []),
				{
					courses_outcomes_id: uuidv4(),
					outcome: {
						outcome_id: uuidv4(),
						outcome_order: courseOutcome?.length,
						outcome_text: '',
					},
				},
			];
			return { ...prevState, courses_outcomes: newOutcomesArray };
		});
	};

	const removeResource = (id: string) => {
		setCourseDraftData((prevState: Course) => {
			const { courses_resources: coursesResources } = prevState;

			const newResourceArray = coursesResources?.filter(
				(resource: CoursesResourcesEntity) => resource.course_resources_id !== id
			);

			return { ...prevState, courses_resources: newResourceArray };
		});
	};

	const addResource = (type: string) => {
		setCourseDraftData((prevState: Course) => {
			const { courses_resources: coursesResources } = prevState;
			const newOutcomesArray = [
				...(coursesResources ?? []),
				{
					course_resources_id: uuidv4(),
					resource: {
						resource_author: '',
						resource_detail: '',
						resource_isbn: '',
						resource_id: uuidv4(), // populated with DB
						resource_title: '',
						resource_type: type,
					},
				},
			];

			return { ...prevState, courses_resources: newOutcomesArray };
		});
	};

	const updateResources = (resources: CoursesResourcesEntity) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			courses_resources: resources,
		}));
	};

	const addFocus = () => {
		setCourseDraftData((prevState: Course) => {
			const { courses_focuses: coursesFocuses } = prevState;
			const newFocusArray = [
				...(coursesFocuses ?? []),
				{
					courses_focuses_id: uuidv4(),
					focus: {
						focus_order: coursesFocuses?.length,
						focus_type: '',
						focus_id: uuidv4(),
						focus_title: '',
						focuses_skills: [],
					},
				},
			];

			return { ...prevState, courses_focuses: newFocusArray };
		});
	};

	const removeFocus = (id: string) => {
		setCourseDraftData((prevState: Course) => {
			const { courses_focuses: coursesFocuses } = prevState;

			const newFocusArray = coursesFocuses?.filter(
				(focus: any) => focus.courses_focuses_id !== id
			);

			return { ...prevState, courses_focuses: newFocusArray };
		});
	};

	const updateFocus = (currentFocus: CourseFocusEntity) => {
		setCourseDraftData((prevState: Course) => ({
			...prevState,
			courses_focuses: currentFocus,
		}));
	};

	const addSkill = (id: string, type: string) => {
		setCourseDraftData((prevState: Course) => {
			const { courses_focuses: coursesFocuses } = prevState;

			if (typeof coursesFocuses === 'object') {
				const matchingFocus = coursesFocuses?.find(
					(focus: CourseFocusEntity) => focus.courses_focuses_id === id
				);

				const newSkill = {
					focuses_skills_id: uuidv4(), // ?? needs to be populated
					skill: {
						skill_id: uuidv4(),
						skill_text: '',
						skill_type: type,
					},
				};

				const newFocus = coursesFocuses?.map((focus: CourseFocusEntity) => {
					if (focus.courses_focuses_id === id) {
						const original = focus?.focus.focuses_skills ?? [];
						const newSkillArr = [...original, newSkill];
						const focusClone = {
							...focus.focus,
							focuses_skills: newSkillArr,
						};
						return { ...focus, focus: focusClone };
					}
					return focus;
				});

				if (typeof matchingFocus === 'object') {
					return { ...prevState, courses_focuses: newFocus };
				}
			}
			// return initial state on fail
			return { ...prevState };
		});
	};

	const removeSkill = (data: { focusSkillId: string; courseFocusId: string }) => {
		const { focusSkillId, courseFocusId } = data;

		setCourseDraftData((prevState: Course) => {
			const updatedCourseFocus = prevState.courses_focuses?.map((courseFocus: any) => {
				if (courseFocus.courses_focuses_id === courseFocusId) {
					const filteredSkills = courseFocus.focus.focuses_skills.filter(
						(focus: any) => focus.focuses_skills_id !== focusSkillId
					);

					const updatedFocus = {
						...courseFocus,
						focus: {
							...courseFocus.focus,
							focuses_skills: filteredSkills,
						},
					};
					return updatedFocus;
				}
				return courseFocus;
			});
			return { ...prevState, courses_focuses: updatedCourseFocus };
		});
	};

	const updateCourseReducer = (state: any, action: any) => {
		switch (action) {
			case EditorType.DESCRIPTION:
				return updateDescription(state);
			case EditorType.EXTRAINFO:
				return updateAdditionalInfo(state);
			case EditorType.OUTCOME:
				return updateOutcome(state);
			case EditorType.RESOURCES:
				return updateResourceInfo(state);
			default:
				return state;
		}
	};

	const lessonPlans: any = [
		{
			id: uuidv4(),
			title: '',
			introduction: '',
			lessons: '',
			objectives: '',
			resources: '',
			enrichment: '',
			supportMaterial: '',
			bellringer: '',
			hasAdvanced: true,
		},
	];

	const { setLessonPlans } = useCourseLessonsStore();
	useEffect(() => {
		subIdRef.current = courseData?.submission_id ?? '';
		setCourseData(courseInfo);
		setLessonPlans(JSON.parse(courseData?.course_lesson_plans ?? '[]'));
	}, [courseInfo, courseData, setLessonPlans]);

	useEffect(() => {
		setCourseData(courseInfo);
	}, [courseInfo, courseData]);

	useEffect(() => {
		if (courseInfo) {
			const courseVersion =
				courseInfo.is_saved_draft && !isApprovalMode
					? courseInfo.working_copy
					: courseInfo.course_draft;
			setCourseDraftData(JSON.parse(courseVersion));
		}
	}, [courseInfo, isApprovalMode]);

	return (
		<CourseEditContext.Provider
			value={{
				addFocus,
				addOutcome,
				addOutcomeTag,
				addResource,
				addSkill,
				canEdit,
				courseData,
				courseDraftData,
				currentUserData,
				editorRefs,
				isAlignment,
				isApprovalMode,
				isCourseOwner,
				isFetched,
				isFetchedAfterMount,
				isPendingApproval,
				lessonPlans,
				removeFocus,
				removeOutcome,
				removeResource,
				removeSkill,
				saveDraft,
				submitCourse,
				updateAdditionalInfo,
				updateCourseReducer,
				updateDescription,
				updateDraftStatus,
				updateEditorRef,
				updateFocus,
				updateFocusOrder,
				updateOutcome,
				updateOutcomeOrder,
				updatePrereq,
				updateResourceInfo,
				updateResources,
				updatePlans,
			}}>
			<Fragment key={courseData?.course_id}>{children}</Fragment>
		</CourseEditContext.Provider>
	);
};

export default CourseEditContext;

export { CourseEditProvider };
