import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useCourseAlignment() {
	return useQuery(
		['get-course-alignment'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const courseQuery = gql`
				query GetCourseMap {
					course_map(where: { isDraft: { _eq: false } }) {
						course_map_json
					}
				}
			`;

			const { course_map: map } = await graphQLClient.request(courseQuery);

			return JSON.parse(map[0]?.course_map_json ?? '{}');
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}

export function useAllAlignmentCourses() {
	return useQuery(
		['get-all-alignment-courses'],
		async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const { courses } = await graphQLClient.request(
				gql`
					query GetCourseOutcome {
						courses {
							course_name
							course_id
							course_description
							course_resource_info
							course_prereq
							course_extras
							course_discipline
							course_division
							grade
							last_updated
							courses_outcomes {
								courses_outcomes_id
								outcome {
									outcome_order
									outcome_text
									outcome_id
								}
							}
							courses_resources {
								course_resources_id
								resource {
									resource_author
									resource_detail
									resource_isbn
									resource_id
									resource_title
									resource_type
								}
							}
							courses_users {
								user_id
								course_id
								user {
									user_id
									user_first
									user_last
									dept_chair {
										user_id
									}
								}
							}
							courses_focuses {
								courses_focuses_id
								focus {
									focus_title
									focus_type
									focus_order
									focus_id
									focuses_skills {
										focuses_skills_id
										skill {
											skill_id
											skill_text
											skill_type
										}
									}
								}
							}
						}
					}
				`
			);
			return courses;
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}
