import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { useUserRole } from '@/hooks/useAuth';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useGetUsers() {
	const { data: userRoleData } = useUserRole();

	return useQuery(
		['get-users'],
		async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

			const { users } = await graphQLClient.request(
				gql`
					query {
						users(order_by: { user_first: asc }) {
							user_email
							user_firebase_id
							user_first
							user_id
							user_last
							user_role
							courses {
								course_id
								course_name
							}
							dept_chair {
								user_last
								user_first
								user_id
							}
							courses_users {
								courses_users_id
								user_id
								course_id
								course {
									course_name
								}
							}
						}
					}
				`
			);
			return users;
		},
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
		}
	);
}

export function useDeptChairs() {
	const { data: userRoleData } = useUserRole();
	return useQuery(
		['get-dept-chairs'],
		async () => {
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('content-type', `application/json`);
			graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

			const { users } = await graphQLClient.request(
				gql`
					query {
						users(
							where: { user_role: { _eq: "dept" } }
							order_by: { user_first: asc }
						) {
							user_first
							user_last
							user_id
							user_firebase_id
						}
					}
				`
			);
			return users;
		},
		{
			staleTime: Infinity,
		}
	);
}

export function useGetModalCourses() {
	const { data: userRoleData } = useUserRole();
	return useQuery(['get-modal-corses'], async () => {
		const auth = getAuth();
		const token = await auth.currentUser?.getIdToken();

		graphQLClient.setHeader('authorization', `Bearer ${token}`);
		graphQLClient.setHeader('content-type', `application/json`);
		graphQLClient.setHeader('x-hasura-role', userRoleData?.user_role);

		const { courses } = await graphQLClient.request(
			gql`
				query {
					courses(order_by: { course_name: asc }) {
						course_name
						course_id
					}
				}
			`
		);
		return courses;
	});
}
