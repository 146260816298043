import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { gql, GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export const useSession = () => {
	const auth = getAuth();
	const user = auth.currentUser;

	return user;
};

export const useAuth = () => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}

	const [userRef, setUserRef] = useState(() => {
		const auth = getAuth();
		const user = auth.currentUser;

		return {
			initializing: !user,
			user,
		};
	});

	function onChange(user: any) {
		setUserRef({ initializing: false, user });
	}

	useEffect(() => {
		const auth = getAuth();
		// listen for auth state changes
		const unsubscribe = onAuthStateChanged(auth, (user) => onChange(user));

		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	return userRef;
};

export const useUserRole = (refetchOnMount: boolean | 'always' = true) => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}
	return useQuery(
		['get-current-user-role'],
		async () => {
			const auth = await getAuth();
			const user = auth.currentUser;
			graphQLClient.setHeader('content-type', `application/json`);

			const query = gql`
				query GetCurrentUser($id: String) {
					users(where: { user_firebase_id: { _eq: $id } }) {
						user_role
						user_id
					}
				}
			`;

			const { users } = await graphQLClient.request(query, { id: user?.uid });
			return users[0];
		},
		{
			staleTime: Infinity,
			refetchOnMount,
		}
	);
};

const CURRENT_USER = gql`
	query GetCurrentUser($id: String) {
		users(where: { user_firebase_id: { _eq: $id } }) {
			user_id
			user_email
			user_first
			user_last
			user_role
			dept_chair {
				user_email
			}
		}
	}
`;

export const currentUser = async (): Promise<Omit<User, 'courses' | 'courses_users'>> => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}
	const auth = await getAuth();
	const user = auth.currentUser;
	const data = await graphQLClient.request(CURRENT_USER, { id: user?.uid });

	return data.users?.[0];
};

export const useCurrentUser = (enabled = true) => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}

	return useQuery(
		['get-current-user'],
		async () => {
			const auth = await getAuth();
			const user = auth.currentUser;
			graphQLClient.setHeader('content-type', `application/json`);
			const query = gql`
				query GetCurrentUser($id: String) {
					users(where: { user_firebase_id: { _eq: $id } }) {
						user_id
						user_email
						user_first
						user_last
						user_role
						dept_chair {
							user_email
						}
					}
				}
			`;

			const { users } = await graphQLClient.request(query, { id: user?.uid });
			return users[0];
		},
		{
			staleTime: Infinity,
			enabled,
		}
	);
};
